<template>
    <!--components/GuidedTourBtn.vue-->

    <NuxtLink no-prefetch class="interactive-demo-link prevent_vertical_expansion"
        to='/n/interactive-demo'
        target='_blank'
    >
        <button class="btn guided-tour-btn prevent_vertical_expansion" :class="{'btn-outline-primary': ! highlight_interactive_demo_ref.valueOf(), 'btn-primary': highlight_interactive_demo_ref.valueOf()}">Interactive Demo</button>
    </NuxtLink>
    <!--
    <button class="btn btn-outline-primary guided-tour-btn prevent_vertical_expansion" @click="show_guided_tour">Guided Tour</button>
    <teleport to="body">
        <GuidedTourModal title=""  @closeModal="hide_modal" :show_close="false" :title="' '" v-if="guided_tour_visible_state.valueOf()"/>
    </teleport>
    -->
</template>

<script setup>
    let guided_tour_visible_state = useGuidedTourVisable();

    const show_guided_tour = (event) => {
        guided_tour_visible_state.value = true;
    }

    const hide_modal = () => {
        guided_tour_visible_state.value = false;
        localStorage.setItem('display_guided_tour','0');
    };

    let highlight_interactive_demo_ref = ref(false);
    const userInfoStore = useUserInfoStore();
    let user_info = userInfoStore.getUserInfo;
    if ((isAuthenticated()) && (user_info.org.toLowerCase().startsWith('trial'))) {
        highlight_interactive_demo_ref.value = true;
    }

</script>

<style scoped>
.prevent_vertical_expansion {
    align-self: center;
}

.guided-tour-btn:hover {
    border: 1px solid blue;
}
</style>
